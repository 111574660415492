<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataSignature"
            :loading="loading"
            dense
          >
            <template v-slot:top>
              <v-toolbar flat dense class="mt-2 mb-3">
                <v-row>
                  <v-col md="6">
                    <v-text-field
                      outlined
                      dense
                      v-model="search"
                      append-icon="mdi-magnify"
                      autocomple="off"
                      placeholder="Search..."
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col md="3" align="right">
                    <v-select
                      placeholder="Pilih gudang"
                      v-model="dataFilterByGudang"
                      dense
                      outlined
                      :items="dataGudang"
                      item-text="name"
                      item-value="id"
                      v-on:change="filterByGudang"
                    >
                    </v-select>
                  </v-col>
                  <v-col v-if="modify" md="3" align="right">
                    <v-btn
                      color="#61B15A"
                      class="text-subtitle-2 white--text"
                      style="text-transform: unset !important"
                      @click="checkType(0)"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>
                      Tambah Signature
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:item.is_activated="{ item }">
              <v-chip
                small
                class="font-weight-bold error--text"
                v-if="item.is_activated === 0"
              >
                Disabled
              </v-chip>
              <v-chip small class="font-weight-bold primary--text" v-else>
                Active
              </v-chip>
            </template>

            <template v-if="modify" v-slot:item.actions="{ item }">
              <v-btn
                color="#FFA900"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="checkType(item.id)"
              >
                Edit
              </v-btn>
              <v-btn
                color="#EB4646"
                small
                class="text-caption white--text mr-2"
                style="text-transform: unset !important"
                @click.prevent="hapus(item)"
              >
                <span v-if="item.is_activated === 0">Active</span>
                <span v-else>Disabled</span>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>

    <olah-data
      :olahDataDialog="olahDataDialog"
      :olahDataTitle="olahDataTitle"
      :olahDataBtn="olahDataBtn"
      :olahData="olahData"
      :dataGudang="dataGudang"
      :dataSignatureType="dataSignatureType"
      :typeAction="typeAction"
      @closeOlahDataDialog="closeOlahDataDialog"
      @reloadData="reloadData"
    />

    <v-dialog v-model="progress" width="500">
      <v-card>
        <v-card-text class="pt-5">
          <v-progress-linear indeterminate color="#FFA900"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="popDialog" width="450">
      <v-card color="white" rounded>
        <v-card-title class="justify-center text-h6 black--text">
          {{ popTitle }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="light-green darken-1"
            class="white--text"
            @click="closePopDialog"
            >Tutup</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="text-h6 font-weight-bold black--text">
          Ubah status Signature
        </v-card-title>
        <v-card-subtitle class="mt-1 text-subtitle-2 black--text">
          Status Signature akan dirubah. Yakin melanjutkan proses ini?
        </v-card-subtitle>
        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            color="#61B15A"
            style="text-transform: unset !important"
            @click="closeDelete"
          >
            Batal
          </v-btn>
          <v-btn
            color="#EB4646"
            class="white--text"
            style="text-transform: unset !important"
            @click="deleteItemConfirm"
          >
            Proses
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
import olahData from "./olahData.vue";

export default {
  components: { olahData },
  props: { modify: Boolean },
  data: () => ({
    search: "",
    loading: false,
    progress: false,
    headers: [
      {
        text: "Deskripsi",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "desc",
      },
      {
        text: "Note",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name_signature_type",
      },
      {
        text: "Nama",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
      {
        text: "Gudang",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name_gudang",
      },
      {
        text: "Jabatan",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "jabatan",
      },
      {
        text: "Status",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "is_activated",
      },
      {
        text: "Action",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "actions",
      },
    ],
    dataSignature: [],
    dataGudang: [],
    dataSignatureType: [],
    dataFilterByGudang: null,
    itemsPerPage: 500,
    typeAction: "",
    olahDataTitle: "",
    olahDataBtn: "",
    olahDataDialog: false,
    olahData: {},
    popDialog: false,
    popTitle: "",
    deleteData: {},
    status: "",
    dialogDelete: false,
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),
    getData() {
      this.loading = true;
      if (this.dataFilterByGudang === null) {
        axios
          .get("v1/admin/signature/list?limit=" + this.itemsPerPage)
          .then((response) => {
            this.loading = false;
            this.dataSignature = response.data.data;
            this.getDataGudang();
            this.getDataSignatureType();
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                this.refreshAction(localStorage.getItem("refresh"));
                this.getData();
              }
              console.clear();
            }
          });
      }
      else{
        this.filterByGudang();
      }
    },
    getDataById(id) {
      axios
        .get("v1/admin/signature?id=" + id)
        .then((response) => {
          this.olahData = response.data.data;
          this.typeAction = "edit";
          this.olahDataTitle = "Edit Signature";
          this.olahDataBtn = "Edit & Simpan";
          this.progress = false;
          this.olahDataDialog = true;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
    getDataGudang() {
      axios.get("v1/user/gudang/list?limit=200").then((response) => {
        this.dataGudang = response.data.data;
      });
    },
    getDataSignatureType() {
      axios.get("v1/admin/signature_type/list?limit=200").then((response) => {
        this.dataSignatureType = response.data.data;
      });
    },
    next(page) {
      this.page = page;
      this.getData();
    },
    filterByGudang() {
      this.loading = true;
      axios
        .get(
          "v1/admin/signature/list?limit=" +
            this.itemsPerPage +
            "&gudang_id=" +
            this.dataFilterByGudang
        )
        .then((response) => {
          this.loading = false;
          this.dataSignature = response.data.data;
          this.getDataGudang();
          this.getDataSignatureType();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
    checkType(value) {
      if (value === 0) {
        this.typeAction = "new";
        this.olahDataTitle = "Tambah Signature";
        this.olahDataBtn = "Tambahkan";
        this.olahDataDialog = true;
      } else {
        this.progress = true;
        this.getDataById(value);
      }
    },
    reloadData() {
      if (this.typeAction === "edit") {
        this.popTitle = "Data Signature berhasil diperbaharui";
        this.popDialog = true;
      } else if (this.typeAction === "new") {
        this.popTitle = "Data Signature berhasil ditambahkan";
        this.popDialog = true;
      }
    },
    hapus(value) {
      this.deleteData = value;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.loading = true;
      this.dataSignature = [];
      this.typeAction = "edit";
      const data = this.deleteData;
      if (this.deleteData.is_activated === 0) {
        data.is_activated = 1;
      } else {
        data.is_activated = 0;
      }
      const dataSend = JSON.stringify(data);
      axios
        .put("v1/admin/signature", dataSend, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 201) {
            this.reloadData();
            this.closeDelete();
          }
        })
        .catch((error) => {
          if (error.response) {
            this.loading = false;
            console(error.response);
          }
        });
    },
    closeDelete() {
      this.dataSignature = [];
      this.getData();
      this.dialogDelete = false;
    },
    closePopDialog() {
      this.popTitle = "";
      this.popDialog = false;
    },
    closeOlahDataDialog() {
      this.olahData = {};
      this.dataSignature = [];
      this.getData();
      this.olahDataDialog = false;
    },
  },
  created() {
    this.getData();
  },
};
</script>